import React, {useEffect, useState} from 'react';
import axios from 'axios'
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
// @mui
import { Grid, Container, Typography } from '@mui/material';
// components
// sections
import {
  AppWidgetSummary,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {

  const [candidatos, setCand] = useState([]);
  const [nome, setName] = useState("");
  const navigate = useNavigate()

  useEffect(() => {
    
    axios.get('https://ahoiae.hospedagemelastica.com.br/api/candidatos', {
      mode: 'cors',
      headers:{
        "token": localStorage.getItem('token'),
        "name": localStorage.getItem('name')
      }
    })
    .then(response => {
      setCand(response.data)
     })
  },[])

  useEffect(() => {
    const token = localStorage.getItem('token')

    if (!token) navigate('/login')
  }, [])


  useEffect(() => {
    setName(localStorage.getItem('name'))
  }, [])

  return (
    <>
    

      <Helmet>
        
        <title> Dashboard</title>
      </Helmet>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hey, Bem vindo de volta {nome}!
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Candidatos" total={candidatos.length} icon={'ant-design:user-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Candidatos Pendentes" total={candidatos.filter((candidatos) => candidatos.status.toLowerCase() === 'pendente' ).length} color="warning" icon={'ant-design:bulb-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Candidatos Aprovados" total={candidatos.filter((candidatos) => candidatos.status.toLowerCase() === 'aprovado' ).length} color="success" icon={'ant-design:check-circle-filled'}/>
          </Grid>


          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Candidatos Reprovados" total={(candidatos.filter((candidatos) => candidatos.status.toLowerCase() === 'reprovado' )).length} color="error" icon={'ant-design:close-circle-filled'} />
          </Grid>

        </Grid>
      </Container>

    </>
  );
}
