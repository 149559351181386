import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui

import { Link, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios'
// import axios from 'axios'


import Iconify from '../../../components/iconify';

export default function LoginForm() {
  const [{ email, password }, setAccess] = useState({ email: '', password: '' })

  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const handleAccess = (value, key) => {
    if (key === 'email') setAccess({ email: value, password })
    else if (key === 'password') setAccess({ email, password: value })
  }

  const onSubmit = async () => {
    const data = { email, password }
    setLoading(true)
    try {
      const response = await axios.post("https://ahoiae.hospedagemelastica.com.br/api/usuarios/login", data)
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('name', response.data.usuario.nome)
      localStorage.setItem('email', response.data.usuario.email)
      navigate('/dashboard/app')
    }catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) navigate('/dashboard/app')
  }, [])

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Endereço de E-mail"
          value={email}
          onChange={e => handleAccess(e.target.value, 'email')}
        />

        <TextField
          name="password"
          value={password}
          onChange={e => handleAccess(e.target.value, 'password')}
          label="Senha"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={onSubmit} loading={loading}>
        Entrar no painel
      </LoadingButton>
    </>
  );
}
