
const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
  },
  {
    title: 'Candidatos',
    path: '/dashboard/user',
  },
  {
    title: 'Funcionários',
    path: '/dashboard/funcionario',
  },
];

export default navConfig;
