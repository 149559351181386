import { useState, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Avatar, IconButton, Popover, Button } from '@mui/material';

// import { Navigate } from 'react-router-dom';
// mocks_

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const [close, setClose] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showPopover, setShowPopover] = useState(false)
  const handleShowPopover = () => setShowPopover(!showPopover)

  const handleOpen = (event) => {
    handleShowPopover()
    setOpen(event.currentTarget);
  
  };

  useEffect(() => {
    setName(localStorage.getItem('name'))
    setEmail(localStorage.getItem('email'))

  }, [])

  const handleRedirect = () =>{
    localStorage.removeItem("token")
    localStorage.removeItem('name')
    localStorage.removeItem('email')
    window.location.href="https://neomera-painel.vercel.app/login"
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src="/static/media/Logo.e14a4af2a0245678071a106eeae18f12.svg" alt="photoURL" />
      </IconButton>

      <Popover
        open={showPopover}
        anchorEl={open}
        onClose={handleShowPopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleShowPopover} sx={{ m: 1 }}>
          <Button onClick={handleRedirect}>Logout</Button>
        </MenuItem>
      </Popover>
    </>
  );
}
