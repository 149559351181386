import React, {useEffect, useState} from 'react';
import axios from 'axios'
import { Helmet } from 'react-helmet-async';
import InputMask from 'react-input-mask';
import { filter} from 'lodash';
import { sentenceCase } from 'change-case';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Grid,
//  FormControlLabel,
  Checkbox,
  TableRow,
  Box,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
} from '@mui/material';

import Modal from '@mui/material/Modal';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// mock
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'cpf', label: 'CPF', alignRight: false },
  { id: 'acao', label: 'Ação'},
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.nome.toLowerCase().indexOf(query.toLowerCase())!== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function FunciPage() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [check, setCheck] = useState(false);
  const [orderBy, setOrderBy] = useState('nome');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [usuarios, setCand] = useState([]);
  const [open, setOpen] = useState(false);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("")
  const [todos, setTodos] = useState([]);
  const [modalMode, setModalMode] = useState('create')
  const [id, setId] = useState()
  const isEditMode = modalMode === 'edit'
  const isCreateMode = modalMode === 'create'
  const propsReadOnly = { readOnly: true }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);




    async function validateCPF(cpf) {
      try {
        const response = await axios.get(`https://www.receitaws.com.br/v2/cpf/${cpf}`);
        if (response.data.status === 'ERROR') {

          throw new Error('CPF inválido ou não encontrado');
        }
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    }


  const getTableValues = () => {
    axios.get('https://ahoiae.hospedagemelastica.com.br/api/usuarios', {
      mode: 'cors',
      headers: {
        "token": localStorage.getItem('token')
      },
    })
    .then(response => {
      setCand(response.data)
      console.log(response.data)
      }
    )
  }

  useEffect(() => {
    getTableValues()
  },[])

  const handleSubmit = async (e) => {
    e.preventDefault();

    const todo = {
      nome,
      email,
      cpf,
    };

    const endPoint = isCreateMode
       ? 'https://ahoiae.hospedagemelastica.com.br/api/usuarios'
       : `https://ahoiae.hospedagemelastica.com.br/api/usuarios/${id}`

    await fetch(endPoint, {
      method: isCreateMode ? "POST" : "PUT",
      body: JSON.stringify(todo),
      headers: {
        "Content-Type": "application/json",
        "token": localStorage.getItem('token')
      },
    });

    setTodos((prevState) => [...prevState, todo]);

    setNome("");
    setEmail("");
    setCpf("");
    getTableValues()
    handleClose();

  };

  const handleEdit = data => {
    setNome(data.nome);
    setEmail(data.email);
    setCpf(data.cpf);
    setId(data.id)
  }

    // Delete By ID
  const handleDelete = (id, e) =>{
    e.preventDefault();
    fetch(`https://ahoiae.hospedagemelastica.com.br/api/usuarios/${id}`,{
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
        "token": localStorage.getItem('token')
      },
    }).then(resp => resp.json())
    .then((data) => {
      console.log(data)
      setCand(usuarios.filter((usuario)=> usuario.id !== id))
    })
    .catch(err => console.log(err))
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = usuarios.map((n) => n.nome);
      setSelected(newSelecteds);

      return;
    }
    setSelected([]);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px',
    borderRadius: '9px',
    boxShadow: 24,
    p: 4,
  };

  const handleClick = (event, nome) => {
    const selectedIndex = selected.indexOf(nome);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, nome);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usuarios.length) : 0;

  const filteredUsers = applySortFilter(usuarios, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;




  return (
    <>
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="given-name"
                  name="nome"
                  type="text"
                  onChange={(e) => setNome(e.target.value)}
                  value={nome}
                  required
                  fullWidth
                  label="Nome Completo"
                  autoFocus
                  InputProps={isEditMode && propsReadOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  label="Email"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
              <InputMask
                InputProps={isEditMode}
                mask="999.999.999-99"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
              >
                {() => <TextField 
                  required
                  fullWidth
                  type="text"
                  name="cpf"
                  label="CPF"
                  autoComplete="new-cpf"
                
                />}
              </InputMask>
              </Grid>
            </Grid>
            <Button
              fullWidth
              onClick={handleSubmit}
              type='submit'
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {isCreateMode ? 'Cadastrar' : 'Editar'}
            </Button>
        </Box>
      </Modal>
    </div>
      <Helmet>
        <title> Funcionários </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Funcionários
          </Typography>
          <Button
            onClick={() => {
              setModalMode('create')
              handleOpen()
            }}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Novo usuario
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={usuarios.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, nome, email, cpf } = row;
                    const selectedUser = selected.indexOf(nome) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, nome)} />
                        </TableCell>
                        
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {nome}
                            </Typography>
                          </Stack>
                          
                        </TableCell>

                        <TableCell align="left">{email}</TableCell>

                        <TableCell align="left">{cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}</TableCell>


                        <TableCell align="left">
                          <Button
                            onClick={() => {
                              setModalMode('edit')
                              handleOpen()
                              handleEdit(row)
                            }}
                          >
                            Editar
                          </Button>
                          <Button
                          onClick={(e) => handleDelete(id, e)}
                          >
                            Deletar
                          </Button>
                        </TableCell>
                        
                      </TableRow>
                  
                    );
                  
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Não encontrado
                          </Typography>

                          <Typography variant="body2">
                            Sem resultados para &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Tente procurar por outro nome ou sobrenome.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            labelRowsPerPage={"usuarios por Página"}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={usuarios.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
